// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Card, CardContent, CardHeader } from '@mui/material';


import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { fetchBarChartData } from '../api';
import Layout from './Layout';
import CardGraph from './CardGraph'; // Importiamo il nuovo componente

const Dashboard = () => {
    const [ChartData1, setChartData1] = useState({ labels: [], datasets: [] });
    const [ChartData2, setChartData2] = useState({ labels: [], datasets: [] });
    const [ChartData3, setChartData3] = useState({ labels: [], datasets: [] });
    const [ChartData4, setChartData4] = useState({ labels: [], datasets: [] });
    const [ChartData5, setChartData5] = useState({ labels: [], datasets: [] });
    const [ChartData6, setChartData6] = useState({ labels: [], datasets: [] });
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [loading3, setLoading3] = useState(true);
    const [loading4, setLoading4] = useState(true);
    const [loading5, setLoading5] = useState(true);
    const [loading6, setLoading6] = useState(true);

  // Stati per i dati dei grafici
  const [ChartDataViaggi, setChartDataViaggi] = useState({ labels: [], datasets: [] });
  const [ChartDataPresenze, setChartDataPresenze] = useState({ labels: [], datasets: [] });
  const [loadingViaggi, setLoadingViaggi] = useState(true);
  const [loadingPresenze, setLoadingPresenze] = useState(true);



    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseViaggi = await fetchBarChartData('/graph/mteffviaggi');
                const responsePresenze = await fetchBarChartData('/graph/mteffpresenze');
                
                setChartDataViaggi(processDataViaggi(responseViaggi));
                setLoadingViaggi(false);
                
                setChartDataPresenze(processDataPresenze(responsePresenze));
                setLoadingPresenze(false);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    navigate('/');
                }
            }
        };
        fetchData();
    }, [navigate]);

  // Funzione per elaborare i dati di viaggi
  const processDataViaggi = (data) => {
    const labels = data.map(item => item.settimana);
    const dataset = data.map(item => item.percentuale_risposte_complessiva);

    return {
        labels,
        datasets: [
            {
                label: 'Efficacia Solleciti Viaggi (%)',
                data: dataset,
                fill: false,
                borderColor: '#36A2EB',
                tension: 0.1,
                backgroundColor: ['#36A2EB']
            }
        ]
    };
};

// Funzione per elaborare i dati di presenze
const processDataPresenze = (data) => {
    const labels = data.map(item => item.settimana);
    const dataset = data.map(item => item.percentuale_presenze_complessiva);

    return {
        labels,
        datasets: [
            {
                label: 'Efficacia Solleciti Presenze (%)',
                data: dataset,
                fill: false,
                borderColor: '#FF6384',
                tension: 0.1,
                backgroundColor: ['#36A2EB']
            }
        ],
      
    };
};
    useEffect(() => {
        const fetchData = async () => {
            try {
                const Data1 = await fetchBarChartData('/graph/teachers');
                const Data2 = await fetchBarChartData('/graph/corsitot');
                const Data3 = await fetchBarChartData('/graph/alunnitot');
                const Data4 = await fetchBarChartData('/graph/mtperf');
                const Data5 = await fetchBarChartData('/graph/prezzimediperregione');
                const Data6 = await fetchBarChartData('/graph/accessigiornalieri');

                setChartData1(processData1(Data1));
                setLoading1(false);  // Una volta caricati i dati, disabilita il loader
                setChartData2(processData2(Data2));
                setLoading2(false);
                setChartData3(processData3(Data3));
                setLoading3(false);
                setChartData4(processData4(Data4));
                setLoading4(false);
                setChartData5(processData5(Data5));
                setLoading5(false);
                setChartData6(processData6(Data6));
                setLoading6(false);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    navigate('/');
                }
            }
        };
        fetchData();
    }, [navigate]);

    const processData1 = (data) => {
        const labels = data.map(item => item.meta_value);
        const dataset = data.map(item => item.numteachers);

        return {
            labels,
            datasets: [{
                label: 'Teachers',
                data: dataset,
                backgroundColor: ['#FF6384', '#36A2EB']
            }]
        };
    };

    const processData2 = (data) => {
        const labels = [...new Set(data.map(item => item.course_type))];
        const courseStatus = [...new Set(data.map(item => item.course_status))];

        const datasets = courseStatus.map(status => ({
            label: status,
            data: labels.map(label => {
                const course = data.find(item => item.course_status === status && item.course_type === label);
                return course ? course.num_courses : 0;
            }),
            backgroundColor: status === 'Corsi attivi' ? '#36A2EB' : '#FF6384'
        }));

        return {
            labels,
            datasets,
        };
    };

    const processData3 = (data) => {
        const labels = ['Totali','Non più associati', 'Associati a corsi','Associati a Corsi Passati',  'Associati a Corsi Attivi', 'Associati a Corsi Futuri','Loggati', 'Attivi recentemente'];
        const dataset = data.length ? [data[0].alunni_totali,data[0].alunni_passati,data[0].alunni_associati, data[0].alunni_pass, data[0].alunni_attivi, data[0].alunni_futuri,data[0].loggati, data[0].loggatirecentemente] : [];

        return {
            labels,
            datasets: [{
                label: 'DinoAlunni',
                data: dataset,
                backgroundColor: ['#FF6384','#848484', '#36A2EB', '#FF6384', '#36A2EB', '#FFCE56','#36A2EB','#36EBA2']
            }]
        };
    };

    const processData4 = (data) => {
        const labels = ['Corsi Attivi Extrascolastici', 'Senza Alunni', 'Con roadmap non inviate','Con presenze non registrate',];
        const dataset = data.length ? [data[0].totale, data[0].senza_alunni, data[0].senza_roadmap, data[0].senza_presenze] : [];

        return {
            labels,
            datasets: [{
                label: 'Attività MT Freelance (i dati sono aggiornati 1 volta al giorno) ',
                data: dataset,
                backgroundColor: [ '#36A2EB', '#FF6384','#FFCE56','#FFCE56']
            }]
        };
    };

    const processData5 = (data) => {
        const labels = Object.keys(data);
        const dataset = Object.values(data);

        return {
            labels,
            datasets: [{
                label: 'Prezzo Medio per Regione',
                data: dataset,
                backgroundColor: dataset.map((_, index) => ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56'][index % 7])
            }]
        };
    };

    const processData6 = (data) => {
        const labels = data.map(item => item.access_date);  // Mappare le date degli accessi
        const dataset = data.map(item => item.unique_users);  // Mappare gli utenti unici
    
        return {
            labels,
            datasets: [{
                label: 'Accessi unici settimanali delle MT all\'app',
                data: dataset,
                backgroundColor: '#36A2EB'  // Colore personalizzato
            }]
        };
    };


    return (
        <Layout>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
                <CardGraph title="Magic Teachers Attive" chartData={ChartData1} isLoading={loading1}  showValues={true} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
                <CardGraph title="Corsi Caricati" chartData={ChartData2} isLoading={loading2} showValues={true}  />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
                <CardGraph title="DinoAlunni" chartData={ChartData3} isLoading={loading3} showValues={true} />
            </Grid>
          
            <Grid item xs={12} sm={6} md={6} lg={4}>
                <CardGraph title="Prezzo Medio per Regione" chartData={ChartData5} isLoading={loading5}  showValues={true} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
                <CardGraph title="Accessi MT" chartData={ChartData6} isLoading={loading6} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
                <CardGraph title="Corsi attivi MT Freelance" chartData={ChartData4} isLoading={loading4}  showValues={true}  />
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <CardGraph title="Efficacia Settimanale Solleciti Viaggi" chartData={ChartDataViaggi} isLoading={loadingViaggi} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <CardGraph title="Efficacia Settimanale Solleciti Presenze" chartData={ChartDataPresenze} isLoading={loadingPresenze} />
                </Grid>
            </Grid>

        </Grid>
    </Layout>
    );
};

export default Dashboard;
