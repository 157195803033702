import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Grid, Typography,MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import axios from '../axios';

const AlunnoModal = ({ open, onClose, onSave, alunno, setAlunno }) => {
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [corsi, setCorsi] = useState([]); // Stato per memorizzare l'elenco dei corsi



    const handleChange = (e) => {
        const { name, value } = e.target;
        setAlunno(prevAlunno => ({ ...prevAlunno, [name]: value }));

        // Clear error for the field
        if (errors[name]) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: null }));
        }
    };

    const handleYearChange = (date) => {
        setAlunno(prevAlunno => ({ ...prevAlunno, anno_di_nascita: date ? date.year() : '' }));

        // Clear error for anno_di_nascita field
        if (errors.anno_di_nascita) {
            setErrors(prevErrors => ({ ...prevErrors, anno_di_nascita: null }));
        }
    };

   // Funzione di validazione email
   const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const validateFields = () => {
    const newErrors = {};
    if (!alunno.first_name) newErrors.first_name = 'Campo Obbligatorio';
    if (!alunno.last_name) newErrors.last_name = 'Campo Obbligatorio';

    // Controllo validità dell'email
    if (!alunno.uemail) {
        newErrors.uemail = 'Campo Obbligatorio';
    } else if (!isValidEmail(alunno.uemail)) {
        newErrors.uemail = 'Indirizzo email non valido';
    }

    if (!alunno.course_id) newErrors.seleziona_corso = 'Campo Obbligatorio';
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
};

    useEffect(() => {
        const fetchCorsi = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.post('/datahandler/corsi-insegnante', { token });
                setCorsi(response.data);
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };

        fetchCorsi();
    }, []);


    useEffect(() => {
        // Validate form whenever alunno changes
        setIsFormValid(validateFields());
    }, [alunno]);

    const handleSave = () => {
        if (validateFields()) {
            onSave();
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{alunno.id ? 'Modifica Alunno' : 'Crea Nuovo Alunno'}</DialogTitle>
            <DialogContent>
                <Typography variant="h6" gutterBottom>Dati anagrafici</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Nome alunno *"
                            name="first_name"
                            type="text"
                            fullWidth
                            value={alunno.first_name}
                            onChange={handleChange}
                            error={!!errors.first_name}
                            helperText={errors.first_name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Cognome alunno *"
                            name="last_name"
                            type="text"
                            fullWidth
                            value={alunno.last_name}
                            onChange={handleChange}
                            error={!!errors.last_name}
                            helperText={errors.last_name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                views={['year']}
                                label="Anno di nascita"
                                value={alunno.anno_di_nascita ? moment().year(alunno.anno_di_nascita) : null}
                                onChange={handleYearChange}
                                maxDate={moment()}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        error={!!errors.anno_di_nascita}
                                        helperText={errors.anno_di_nascita}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Nome e cognome genitore di riferimento"
                            name="nome_e_cognome_genitore"
                            type="text"
                            fullWidth
                            value={alunno.nome_e_cognome_genitore}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>

                <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>Dati di accesso al DinoClub</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="E-mail *"
                            name="uemail"
                            type="email"
                            fullWidth
                            value={alunno.uemail}
                            onChange={handleChange}
                            error={!!errors.uemail}
                            helperText={errors.uemail}
                        />
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth error={!!errors.seleziona_corso}>
                            <InputLabel>Seleziona Corso *</InputLabel>
                            <Select
                                label="Seleziona Corso *"
                                name="course_id"
                                value={alunno.course_id || ''}
                                onChange={handleChange}
                            >
                                {corsi.map((corso) => (
                                    <MenuItem key={corso.ID} value={corso.ID}>
                                        {corso.post_title}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.seleziona_corso && (
                                <Typography variant="caption" color="error">
                                    {errors.seleziona_corso}
                                </Typography>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>

                <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>Dati fatturazione</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Codice fiscale alunno"
                            name="codice_fiscale"
                            type="text"
                            fullWidth
                            value={alunno.codice_fiscale}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Indirizzo"
                            name="indirizzo_fatturazione"
                            type="text"
                            fullWidth
                            value={alunno.indirizzo_fatturazione}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="CAP"
                            name="cap_fatturazione"
                            type="text"
                            fullWidth
                            value={alunno.cap_fatturazione}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="Città"
                            name="citta_fatturazione"
                            type="text"
                            fullWidth
                            value={alunno.citta_fatturazione}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Telefono"
                            name="telefono_fatturazione"
                            type="text"
                            fullWidth
                            value={alunno.telefono_fatturazione}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>

                <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>Dati per comunicazioni</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Nome e cognome padre"
                            name="nome_e_cognome_padre"
                            type="text"
                            fullWidth
                            value={alunno.nome_e_cognome_padre}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Telefono padre"
                            name="telefono_padre"
                            type="text"
                            fullWidth
                            value={alunno.telefono_padre}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Nome e cognome madre"
                            name="nome_e_cognome_madre"
                            type="text"
                            fullWidth
                            value={alunno.nome_e_cognome_madre}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Telefono madre"
                            name="telefono_madre"
                            type="text"
                            fullWidth
                            value={alunno.telefono_madre}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="E-mail per comunicazioni"
                            name="email_per_comunicazioni"
                            type="email"
                            fullWidth
                            value={alunno.email_per_comunicazioni}
                            onChange={handleChange}
                        />
                    </Grid>

                  
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Annulla
                </Button>
                <Button  variant="contained"  onClick={handleSave} color="primary" disabled={!isFormValid}>
                    Salva
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlunnoModal;
