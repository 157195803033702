// src/components/MtAuth.js
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../axios';

const MtAuth = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const msgtoken = query.get('msgtoken');
        const token = query.get('token');

        if (msgtoken) {
            axios.post('/auth/MTauth', { msgtoken })
                .then(response => {
                    if (response.data.status === 'success') {
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('first_name', response.data.first_name);
                        localStorage.setItem('last_name', response.data.last_name);
                        localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
                         // Redirect based on permissions
                        
                        
                            navigate('/');
                        
                    } else {
                        console.error('Errore:', response.data.message);
                        navigate('/');
                    }
                })
                .catch(error => {
                    console.error('Errore nella richiesta:', error);
                    navigate('/');
                });
        } else {
         
            if (token) {
                axios.post('/auth/MTauthT', { token })
                    .then(response => {
                        if (response.data.status === 'success') {
                            localStorage.setItem('token', response.data.token);
                            localStorage.setItem('first_name', response.data.first_name);
                            localStorage.setItem('last_name', response.data.last_name);
                            localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
                             // Redirect based on permissions
                            
                            
                                navigate('/');
                            
                        } else {
                            console.error('Errore:', response.data.message);
                            navigate('/');
                        }
                    })
                    .catch(error => {
                        console.error('Errore nella richiesta:', error);
                        navigate('/');
                    });
            } else {
             
         
            navigate('/');

            }



        }
    }, [location, navigate]);

    return (
        <div>
            <h1>Autenticazione in corso...</h1>
        </div>
    );
};

export default MtAuth;
