import React, { useEffect, useState } from 'react';
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/tabulator.min.css';
import Layout from './Layout';
import axios from '../axios';
import './CorsiCaricatiTable.css'; // Import custom styles
import 'react-tabulator/css/tabulator_simple.min.css';
import './GlobalRoadmap.css'; // Custom CSS for styling
import { DateTime } from 'luxon';

// Configura Tabulator o esegui qualsiasi altra logica

const GlobalRoadmap = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const result = await axios.post('/datahandler/globalroadmap', { token });
        const formattedCourses = result.data.map(course => ({
          ...course,
          data_inizio_corso: formatDate(course.data_inizio_corso),
          data_fine_corso: formatDate(course.data_fine_corso),
        }));
        setCourses(formattedCourses);
        setLoading(false);
      } catch (error) {
        console.error('Errore durante il recupero dei dati:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateStr) => {
    if (!dateStr) return '';
    const [year, month, day] = [dateStr.slice(0, 4), dateStr.slice(4, 6), dateStr.slice(6, 8)];
    return `${day}/${month}/${year}`;
  };

  const fetchRoadmapData = async (cell, idCorso, idAlunno) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post('datahandler/corso-alunno-roadmap', {
        idCorso,
        idAlunno,
        token: token
      });
      const roadmapData = response.data;
      const roadmapContent = roadmapData.map(item => {
        const color = item.sent === '1' ? 'green' : 'red';
        return `<div style="color: ${color}">${item.codice}</div>`;
      }).join('');
      cell.getElement().innerHTML = roadmapContent;
    } catch (error) {
      console.error('Error fetching roadmap:', error);
      cell.getElement().innerHTML = 'Errore nel caricamento';
    }
  };



  const roadmapFormatter = (cell, formatterParams, onRendered) => {
    const rowData = cell.getData();
    const idCorso = rowData.idcorso;
    const idAlunno = rowData.idalunno;
  if(idAlunno>0){
    const fetchRoadmapData = async (cell, idCorso, idAlunno) => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.post('datahandler/corso-alunno-roadmap', {
          idCorso,
          idAlunno,
          token: token
        });
        const roadmapData = response.data;
        const currentDate = new Date();
        const startDate = new Date(
          parseInt(rowData.data_inizio_corso.substring(6, 10)), // Year
          parseInt(rowData.data_inizio_corso.substring(3, 5)) - 1, // Month (0-based index)
          parseInt(rowData.data_inizio_corso.substring(0, 2)) // Day
        );
  
        const roadmapContent = roadmapData.map(item => {
          const phaseDate = new Date(
            parseInt(item.datafase.substring(0, 4)),
            parseInt(item.datafase.substring(5, 7)) - 1,
            parseInt(item.datafase.substring(8, 10))
          );
  
          let backgroundColor = '';
          let tooltip = '';
  
          if (currentDate < startDate) {
            backgroundColor = '#d3d3d3'; // Gray
            tooltip = 'Corso non iniziato';
          } else if (item.sent === '1') {
            backgroundColor = '#90ee90'; // Green
            tooltip = 'Messaggio inviato';
          } else if (phaseDate < currentDate) {
            backgroundColor = '#ff6961'; // Red
            tooltip = 'Messaggio da inviare, data trascorsa';
          } else {
            backgroundColor = '#fdfd96'; // Yellow
            tooltip = 'Messaggio non inviato';
          }
  
          return `
            <div style="display: inline-block; margin: 0; padding: 0;">
              <button style="
                background-color: ${backgroundColor};
                border: none;
                border-radius: 6px;
                color: black;
                padding: 5px 10px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 10px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                cursor: default;
                margin: 2px;
              " title="${tooltip}">
                ${item.codice}
              </button>
            </div>
          `;
        }).join('');
        cell.getElement().innerHTML = `<div class="roadmap-container">${roadmapContent}</div>`;
      } catch (error) {
        console.error('Error fetching roadmap:', error);
        cell.getElement().innerHTML = 'Errore nel caricamento';
      }
    };
  
    fetchRoadmapData(cell, idCorso, idAlunno);
  }else{return "<div></div>";}
    return "<div>Loading...</div>";
  };
  
  

  const valutazioniFormatter = (cell, formatterParams, onRendered) => {
    const rowData = cell.getData();
    const idAlunno = rowData.idalunno;
    const idCorso = rowData.idcorso;
    if(idAlunno>0){
    const fetchValutazioniData = async (cell, idAlunno,idCorso) => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.post('datahandler/corso-alunno-valutazioni', {
          idAlunno,
          idCorso,
          token: token
        });
        const valutazioniData = response.data;
        const valutazioniContent = valutazioniData.map(item => {
          return `
            <div style="display: inline-block; margin: 0; padding: 0;">
              <button style="
                background-color: #FFB74D;
                border: none;
                border-radius: 6px;
                color: black;
                padding: 5px 10px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 10px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                cursor: default;
                margin: 2px;
              " title="${item.val}">
              ${item.dat.substring(6, 8)}/${item.dat.substring(4, 6)}/${item.dat.substring(0, 4)}

              </button>
            </div>
          `;
        }).join('');
        cell.getElement().innerHTML = `<div class="valutazioni-container">${valutazioniContent}</div>`;
      } catch (error) {
        console.error('Error fetching valutazioni:', error);
        cell.getElement().innerHTML = 'Errore nel caricamento';
      }
    };

    fetchValutazioniData(cell, idAlunno, idCorso);
  }else{
    return "<div></div>";
  }
    return "<div>Loading...</div>";
  };




  const presenzeFormatter = (cell, formatterParams, onRendered) => {
    const rowData = cell.getData();
    const idCorso = rowData.idcorso;
    const idAlunno = rowData.idalunno;
    if(idAlunno>0){
    const fetchPresenzeData = async (cell, idCorso, idAlunno) => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.post('datahandler/corso-alunno-presenze', {
          idCorso,
          idAlunno,
          token: token
        });

        const startDate = new Date(
          parseInt(rowData.data_inizio_corso.substring(6, 10)), // Year
          parseInt(rowData.data_inizio_corso.substring(3, 5)) - 1, // Month (0-based index)
          parseInt(rowData.data_inizio_corso.substring(0, 2)) // Day
        );

        const presenzeData = response.data.filter(item => item.mostralezione == "1");
        const presenzeContent = presenzeData.map(item => {
          let backgroundColor = '';
          let tooltip = '';

          if ( item.presente === '0') {
            backgroundColor = '#2179ff'; // Red
            tooltip = 'Non Presente';
          } else if (item.presente === '1') {
            backgroundColor = '#90ee90'; // Green
            tooltip = 'Presente';
          } else{
            backgroundColor = '#FF0000'; // Red
            tooltip = 'Presenza non salvata!';

          }

          return `
            <div style="display: inline-block; margin: 0; padding: 0;">
              <button style="
                background-color: ${backgroundColor};
                border: none;
                border-radius: 4px;
                color: black;
                padding: 5px 2px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 10px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                cursor: default;
                margin: 0px;
              " title="${tooltip}">
                    ${item.datalezione ? item.datalezione.slice(0, 5)  : 'L ' + item.progr_lezione} <!-- Mostra la data o "Lezione X" -->

              </button>
            </div>
          `;
        }).join('');
        cell.getElement().innerHTML = `<div class="presenze-container">${presenzeContent}</div>`;
      } catch (error) {
        console.error('Error fetching presenze:', error);
        cell.getElement().innerHTML = 'Errore nel caricamento';
      }
    };

    fetchPresenzeData(cell, idCorso, idAlunno);
  }else{
    return "<div></div>";

  }
    return "<div>Loading...</div>";
  };









  const dateSorter = (a, b, aRow, bRow, column, dir, sorterParams) => {
    const dateA = DateTime.fromFormat(a, 'dd/MM/yyyy'); // o qualsiasi formato tu stia usando
    const dateB = DateTime.fromFormat(b, 'dd/MM/yyyy');
    return dateA - dateB;
  };
  

  
  const columns = [
    { title: 'Tipo Insegnante', field: 'tipoteach', headerFilter: 'input', width:80},
    { title: 'Nome Insegnante', field: 'nometeacher', headerFilter: 'input' , width:120},
    { title: 'Cognome Insegnante', field: 'cognometeacher', headerFilter: 'input' , width:120},
    { title: 'Titolo', field: 'post_title', headerFilter: 'input' , width:180},
    { title: 'Data Inizio', field: 'data_inizio_corso', sorter: 'date', hozAlign: 'center' ,sorter: dateSorter, width:100},
    { title: 'Data Fine', field: 'data_fine_corso', sorter: 'date', hozAlign: 'center' ,sorter: dateSorter, width:100},
    { title: 'Lingua', field: 'lingua', headerFilter: 'input' , width:80},
    { title: 'Livello', field: 'livello', headerFilter: 'input' , width:100},
    { title: 'Gruppo Età', field: 'gruppoeta', headerFilter: 'input' , width:180},
    { title: 'Tipo Corso', field: 'course_type', headerFilter: 'input' , width:80},
    { title: 'Nome Alunno', field: 'nome', headerFilter: 'input' , width:80},
    { title: 'Cognome Alunno', field: 'cognome', headerFilter: 'input' , width:80},
    { title: 'Roadmap', field: 'roadmap', formatter: roadmapFormatter, width:580 },
    { title: 'Presenze lezioni', field: 'presenze' ,formatter: presenzeFormatter,  width:680},
    { title: 'Valutazioni', field: 'valutazioni', formatter: valutazioniFormatter, formatterParams: {}, hozAlign: 'left',width:180, headerSort: false },
  ];

  const tableOptions = {
    height: '900px',
    layout: 'fitColumns',
    // movableColumns: true,
    //resizableRows: true,
    //resizableColumns: true,
    virtualDom: true,
    virtualDomBuffer: 300,
    initialSort: [
      { column: 'cognometeacher', dir: 'asc' } // Ordina per cognometeacher in ordine crescente
    ],
  };

  return (
    <Layout>
      <div className="tabulator-container">
        {loading ? (
          <p>Caricamento...</p>
        ) : (
          <ReactTabulator
            data={courses}
            columns={columns}
            options={tableOptions}
            className="custom-tabulator"
          />
        )}
      </div>
    </Layout>
  );
};

export default GlobalRoadmap;
