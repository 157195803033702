import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Switch, Snackbar, Alert, Button, TextField } from '@mui/material';
import axios from '../axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/it'; // Importa la lingua italiana

const PresenzeDinoAlunniDetail = ({ idCorso,idLezione, numLezione, statoLezione,dataLezione, onSaveSuccess }) => {
    const [students, setStudents] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [selectedDate, setSelectedDate] = useState(dataLezione ? dayjs(dataLezione) : null);


    const fetchStudents = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('/datahandler/presenze-insegnante', {
                token,
                idCorso,
                idLezione,
                numLezione,
                statoLezione
            });
            setStudents(response.data.map(student => ({
                ...student,
                presente: statoLezione==0?true:student.Presente // Inizialmente tutti presenti
            })));
           
        } catch (error) {
            console.error('Errore nel recupero degli studenti:', error);
        }
    };

    useEffect(() => {
        fetchStudents();
        setSelectedDate(dayjs(dataLezione)); // Aggiorna selectedDate con la data iniziale passata
            }, [idCorso, numLezione, statoLezione]);

   
    const handleAttendanceToggle = (idAlunno) => {
        setStudents(prevStudents =>
            prevStudents.map(student =>
                student.idAlunno === idAlunno ? { ...student, presente: !student.presente } : student
            )
        );
    };

    const handleSave = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.post('/datahandler/save-presenze', {
                token,
                idCorso,
                idLezione,
                numLezione,
                nuovaData: selectedDate.format('YYYY-MM-DD'), // Passa la nuova data formattata
                students: students.map(student => ({
                    idAlunno: student.idAlunno,
                    presente: student.presente ? 1 : 0
                }))
            });
            setSnackbarMessage('Presenze salvate con successo!');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
            onSaveSuccess(); // Call the callback to notify success
        } catch (error) {
            console.error('Errore nel salvataggio delle presenze:', error);
            setSnackbarMessage('Errore nel salvataggio delle presenze.');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <Box sx={{ width: '100%', mx: 'auto', px: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h5" gutterBottom>
                   Registro Presenze Lezione {numLezione}
                </Typography>
                <Box>
                <Button
  variant="contained"
  color="primary"
  onClick={handleSave}
  disabled={!selectedDate || !selectedDate.isValid()}
>
  Salva
</Button>
                </Box>
            </Box>

            {/* Selezione della data con DatePicker */}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                <DatePicker
                    label="Seleziona Data Lezione"
                    maxDate={dayjs()}
                    value={selectedDate}
                    onChange={(newValue) => setSelectedDate(newValue)} // Aggiorna la data selezionata
                    renderInput={(params) => <TextField {...params} fullWidth required />} // Campo obbligatorio
                />
            </LocalizationProvider>

            {students.length === 0 ? (
                <Typography variant="body1">Nessun alunno trovato.</Typography>
            ) : (
                students.map((student) => (
                    <Card key={student.idAlunno} sx={{ marginBottom: 1 }}>
                        <CardContent
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                backgroundColor: student.presente ? 'transparent' : 'salmon',
                                padding: '8px'
                            }}
                        >
                            <Typography
                                sx={{
                                    textDecoration: student.presente ? 'none' : 'line-through',
                                    flexGrow: 1
                                }}
                            >
                                {student.first_name} {student.last_name}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Switch
                                    checked={student.presente}
                                    onChange={() => handleAttendanceToggle(student.idAlunno)}
                                    color="primary"
                                />
                                <Typography variant="body2">
                                    {student.presente ? 'Presente' : 'Assente'}
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                ))
            )}

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default PresenzeDinoAlunniDetail;
