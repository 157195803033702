import React, { useState, useEffect } from 'react';
import { Button, Box, Grid, Tooltip, Chip,Typography, CircularProgress, Alert, Modal, IconButton , Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Card, CardContent  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import axios from '../axios';
import PresenzeDinoAlunniDetail from './PresenzeDinoAlunniDetail';
import dayjs from 'dayjs';
import 'dayjs/locale/it'; // Importa la lingua italiana
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);


const CorsoLezioni = ({ courseId }) => {
    const [lezioni, setLezioni] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [selectedLezione, setSelectedLezione] = useState(null);
    const [openDialog, setOpenDialog] = useState(false); // Stato per il dialog di conferma
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    


    const fetchLezioni = async () => {
        try {
            const token = localStorage.getItem('token'); // Get the token from local storage
            const response = await axios.post('/datahandler/get-corso-lezioni', {
                token,
                idCorso: courseId
            });

            setLezioni(response.data);
        } catch (error) {
            setError('Errore nel recupero delle lezioni');
        } finally {
            setLoading(false);
        }
    };

    const handleUnlockPresenze = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.post('/datahandler/unlock-presenze', {
                token,
                idCorso: courseId
            });
            setOpenDialog(false); // Chiude il dialog dopo la conferma
            fetchLezioni(); // Ricarica le lezioni dopo lo sblocco
        } catch (error) {
            console.error('Errore nello sblocco delle presenze:', error);
        }
    };


    const getChipProps = (item) => {
        const currentDate = new Date();
        const phaseDate = new Date(
          parseInt(item.datafase.substring(0, 4)),
          parseInt(item.datafase.substring(4, 6)) - 1,
          parseInt(item.datafase.substring(6, 8))
        );
    
        if (item.sent >0) {
          return { backgroundColor: '#4CAF50', color: 'white', tooltip: 'Messaggio inviato' };
        } else if (phaseDate < currentDate) {
          return { backgroundColor: '#ff0000', color: 'white', tooltip: 'Messaggio da inviare, data trascorsa' };
        } else {
          return { backgroundColor: '#B0BEC5', color: 'white', tooltip: 'Messaggio non inviato' };
        }
      };

      const showSnackbar = (message, severity = 'success') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

      const handleChipClick = async (item) => {
        const result = await MySwal.fire({
            title: `Invia ${item.codice}`,
            showCancelButton: false,
            confirmButtonText: `Invia ${item.codice} a tutti i DinoAlunni del corso`,
            showCloseButton: true
        });

        if (result.isConfirmed) {
            await sendViaggio({ idAlunno: 0, idCorso:courseId, idTemplate: item.id }, item.codice, true);
      
        }
    };

    const sendViaggio = async (data, codice, updateCourse) => {
        const token = localStorage.getItem('token');
        try {
            await axios.post('datahandler/send-viaggio', { ...data, token });
            showSnackbar(`${codice} inviato con successo`, 'success');
            if (updateCourse) {
                fetchRoadmap();  // Ricarica tutte le roadmap dopo l'invio
            } 
        } catch (error) {
            console.error('Error sending viaggio:', error);
            showSnackbar('Errore durante l\'invio del viaggio', 'error');
            Swal.fire('Errore', 'Errore durante l\'invio del viaggio', 'error');
        }
    };

        const [roadmap, setRoadmap] = useState([]);
       const fetchRoadmap = async () => {
            const token = localStorage.getItem('token');
            try {
              const response = await axios.post('datahandler/corso-alunno-roadmap', {
                idCorso: courseId,
                token: token,
              });
              setRoadmap(response.data);
            } catch (error) {
              console.error('Error fetching roadmap:', error);
            }
          };
        useEffect(() => {
         
      
          fetchRoadmap();
        }, [courseId]);

        
    useEffect(() => {
       
        fetchLezioni();
    }, [courseId]);

    const handleOpenModal = (lezione) => {
        setSelectedLezione(lezione);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedLezione(null);
        fetchLezioni();
    };

    
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Box>
        <Box textAlign="center">
        <Typography variant="h5" gutterBottom>
            Roadmap
        </Typography>
        <Box display="flex" justifyContent="center" flexWrap="wrap">
                        
               
        {roadmap.map(item => {
          const { backgroundColor, color, tooltip } = getChipProps(item);
          return (
            <Tooltip title={tooltip} >
            <Button
            variant="contained"
            style={{
                margin: '10px',
                backgroundColor: backgroundColor,
                color: color,
                fontSize: '0.75rem', // Opzionale: riduci la dimensione del font
                width: '100px',
                position: 'relative' // Posizionamento relativo per il punto esclamativo
            }}
            onClick={() => handleChipClick(item)}
        >
             {item.codice} 
           
        </Button>
</Tooltip>





          );
        })}
               
               
               
            </Box>
        </Box>

        <Box textAlign="center">
            <Typography variant="h5" gutterBottom>
                Registro presenze
            </Typography>
            <Box display="flex" justifyContent="center" flexWrap="wrap">
                {lezioni.length === 0 ? (




                <Card sx={{ maxWidth: 600, mx: 'auto', mb: 3, p: 2 }}> {/* Imposta una larghezza massima e margini */}
                <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body1">
                            Nessuna lezione trovata o corso non extrascolastico.
                        </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenDialog}
                            sx={{ ml: 2 }} // Margine sinistro per distanziare il pulsante dal testo
                        >
                            Gestisci comunque il registro presenze
                        </Button>
                    </Box>
                </CardContent>
                </Card>






                ) : (
                    lezioneButtons(lezioni, handleOpenModal)
                )}
            </Box>
            </Box>

            {/* Modal */}
            <Modal
    open={openModal}
    onClose={handleCloseModal}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
>
    <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%', // Imposta la larghezza al 90% dello schermo
        maxWidth: 600, // Imposta la larghezza massima
        maxHeight: '90vh', // Limita l'altezza del modal
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        overflowY: 'auto', // Abilita lo scrolling verticale
        position: 'relative', // Ensure the close button is positioned correctly
    }}>
        {/* Close Button */}
        <IconButton
            onClick={handleCloseModal}
            sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: 'grey.500',
            }}
        >
            <CloseIcon />
        </IconButton>

        {selectedLezione && (
            <PresenzeDinoAlunniDetail
                idCorso={courseId}
                idLezione={selectedLezione.id}
                numLezione={selectedLezione.progr_lezione}
                statoLezione={selectedLezione.status}
                dataLezione={selectedLezione.data}
                onSaveSuccess={handleCloseModal} // Chiudi il modal dopo aver salvato
            />
        )}
    </Box>
</Modal>
  <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Vuoi gestire il registro presenze per il corso?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Questa azione permetterà la gestione del registro presenze per questo corso.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Annulla
                    </Button>
                    <Button onClick={handleUnlockPresenze} color="primary" autoFocus>
                        Conferma
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
        };

const lezioneButtons = (lezioni, handleOpenModal) => {
    const today = dayjs(); // Data corrente
    const lezioniAttive = lezioni.filter(lezione => lezione.status === "1"); // Filtra le lezioni con status = 1
    let daysFromLastLezione = 0;
    let lastLezioneIndex = -1;

    // Trova l'indice dell'ultima lezione con status = 1
    if (lezioniAttive.length > 0) {
        const lastLezione = lezioniAttive[lezioniAttive.length - 1];
        const lastLezioneDate = dayjs(lastLezione.data);
        daysFromLastLezione = today.diff(lastLezioneDate, 'day'); // Differenza in giorni
        lastLezioneIndex = lezioni.findIndex(lezione => lezione.id === lastLezione.id);
    }

    // Calcola il numero di lezioni da colorare di rosso (daysFromLastLezione / 7)
    const numLezioniRosse = Math.floor(daysFromLastLezione / 7);

    // Funzione per calcolare il colore in base all'indice e i giorni dall'ultima lezione
    const getButtonColor = (index, lezione) => {
        if (lezione.status === "1") {
            return '#4CAF50'; // Verde per le lezioni con status = 1
        }

        // Se questa lezione è successiva all'ultima lezione con status = 1 e rientra nelle prime numLezioniRosse lezioni
        if (index > lastLezioneIndex && index <= lastLezioneIndex + numLezioniRosse) {
            return '#FF0000'; // Rosso per le lezioni successive, in base ai giorni trascorsi
        }

        return '#B0BEC5'; // Grigio per tutte le altre lezioni
    };

    return lezioni.map((lezione, index) => (
        <Tooltip title={index > lastLezioneIndex && index <= lastLezioneIndex + numLezioniRosse ? "Presenze non registrate" : ""} key={lezione.id}>
            <Button
                variant="contained"
                style={{
                    margin: '10px',
                    backgroundColor: getButtonColor(index, lezione),
                    color: '#fff',
                    fontSize: '0.75rem', // Opzionale: riduci la dimensione del font
                    width: '100px',
                    position: 'relative' // Posizionamento relativo per il punto esclamativo
                }}
                onClick={() => handleOpenModal(lezione)}
            >
                Lezione {lezione.progr_lezione} {lezione.data != null ? dayjs(lezione.data).format('DD/MM/YYYY') : ""}
                {index > lastLezioneIndex && index <= lastLezioneIndex + numLezioniRosse && (
                    <span style={{
                        position: 'absolute',
                        top: 0,
                        right: 5,
                        color: 'yellow',
                        fontSize: '1.2rem',
                        fontWeight: 'bold'
                    }}>!</span> // Punto esclamativo
                )}
            </Button>
        </Tooltip>
    ));
};



export default CorsoLezioni;
