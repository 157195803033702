import React from 'react';
import BarChart from './BarChart';
import { Card, CardContent, CardHeader, Box, CircularProgress } from '@mui/material';

const CardGraph = ({ title, chartData, height = 400, isLoading, showValues = false }) => {
    return (
        <Card>
            <CardHeader
                title={title}
                sx={{ backgroundColor: '#ff9800', color: 'black' }}
                titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
            />
            <CardContent>
                <Box sx={{ height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <BarChart
                            labels={chartData.labels}
                            datasets={chartData.datasets}
                            showValues={showValues}  // Passa showValues a BarChart
                        />
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export default CardGraph;
