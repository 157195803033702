import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Card, CardContent, Avatar, useMediaQuery, Badge, Switch, FormControlLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import './Notifications.css';  // Import the CSS file

import CloseIcon from '@mui/icons-material/Close'; // Icona di chiusura standard
import NotificationsIcon from '@mui/icons-material/Notifications'; // Icona per la notifica
import axios from '../axios';
import Layout from './Layout';
import moment from 'moment';
import 'moment/locale/it'; // Importa il locale italiano

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const theme = useTheme(); // Usa useTheme per accedere al tema
    const [showDeleted, setShowDeleted] = useState(false); // Stato per lo switch
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm')); // Verifica se lo schermo è piccolo
   // Funzione per recuperare le notifiche
    const fetchNotifications = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('/datahandler/notifications', {
                token,
                showDeleted // Passa il parametro showDeleted all'endpoint
            });
            setNotifications(response.data);
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, [showDeleted]);

    const handleRemoveNotification = async (id) => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(`/datahandler/notifications/viewed`, {
                id,
                token
            });
            setNotifications(notifications.filter(notification => notification.id !== id));
        } catch (error) {
            console.error('Error updating notification status:', error);
        }
    };
   // Funzione per gestire il cambio di stato dello switch
   const handleToggleShowDeleted = () => {
    setShowDeleted((prev) => !prev);
    };
    return (
        <Layout>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mb: 2 }}>
            <FormControlLabel
                control={
                    <Switch
                        checked={showDeleted}
                        onChange={handleToggleShowDeleted}
                        color="primary"
                    />
                }
                label={showDeleted ? "Mostra notifiche eliminate" : "Notifiche attive"}
                labelPlacement="start"
            />
        </Box>


            {notifications.length === 0 ? (
                <Typography variant="body1">Nessuna notifica in arrivo.</Typography>
            ) : (
                isSmallScreen ? (
                    <Box sx={{ width: '100%', maxWidth: 300, mx: 'auto', px: 1 }}>
                        <Typography variant="h5" gutterBottom>
                            Ciao Magic Teacher! Hai delle Notifiche:
                        </Typography>
                        {notifications.map(notification => (
                           <Card 
                           key={notification.id} 
                           sx={{ 
                               display: 'flex', 
                               flexDirection: 'column', 
                               backgroundColor: notification.status === "archived" ? '#e00000' : '#d0e4fe', // Sfondo grigio se archiviata
                               color: '#000000', 
                               marginBottom: 2, 
                               borderRadius: 4, 
                               boxShadow: 1 
                           }}
                       >
                           <Box 
                               sx={{ 
                                   display: 'flex', 
                                   justifyContent: 'space-between', 
                                   alignItems: 'center', 
                                   padding: '4px' 
                               }}
                           >
                               {/* Mostra l'icona di chiusura solo se la notifica non è archiviata */}
                               {notification.status !== "archived" && (
                                   <IconButton 
                                       onClick={() => handleRemoveNotification(notification.id)} 
                                       sx={{ color: '#000000' }}
                                   >
                                       <CloseIcon />
                                   </IconButton>
                               )}
                       
                               <Badge 
                                   badgeContent={moment(notification.created_at).format('DD/MM/YYYY')} 
                                   color="success" 
                               />
                               
                               <Avatar sx={{ bgcolor: 'orange', marginRight: 1 }}>
                                   <NotificationsIcon />
                               </Avatar>
                           </Box>
                       
                           <CardContent sx={{ flex: 1, padding: '8px' }}>
                               <Typography 
                                   variant="body2" 
                                   sx={{ mt: 1 }} 
                                   dangerouslySetInnerHTML={{ __html: notification.message }} 
                               />
                           </CardContent>
                       </Card>
                       
                        ))}
                    </Box>
                ) : (
                    <Box sx={{ width: '100%', mx: 'auto', px: 1 }}>
                    <Typography variant="h4" gutterBottom>
                        Ciao Magic Teacher! Hai delle Notifiche:
                    </Typography>
                    {notifications.map(notification => (
                      <Card 
                      key={notification.id} 
                      sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          backgroundColor: notification.status === "archived" ? '#e0e0e0' : '#d0e4fe', // Sfondo grigio se archiviata
                          color: '#000000', 
                          marginBottom: 2, 
                          borderRadius: 2, 
                          boxShadow: 1 
                      }}
                  >
                      {/* Mostra l'icona di chiusura solo se la notifica non è archiviata */}
                      {notification.status !== "archived" && (
                          <IconButton 
                              onClick={() => handleRemoveNotification(notification.id)} 
                              sx={{ color: '#000000' }}
                          >
                              <CloseIcon />
                          </IconButton>
                      )}
                  
                      <CardContent sx={{ flex: 1, padding: '8px', paddingLeft: '16px', position: 'relative' }}>
                          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                              Promemoria per te
                          </Typography>
                          <Typography 
                              variant="body2" 
                              sx={{ mt: 1, minHeight: 48 }} 
                              dangerouslySetInnerHTML={{ __html: notification.message }} 
                          />
                          <Typography 
                              variant="caption" 
                              sx={{ position: 'absolute', top: 8, right: 8, color: '#000000' }}
                          >
                              <Badge 
                                  badgeContent={moment(notification.created_at).format('DD/MM/YYYY')} 
                                  color="success" 
                                  sx={{ marginRight: 1 }} 
                              />
                          </Typography>
                      </CardContent>
                  
                      <Avatar sx={{ bgcolor: 'orange', margin: 1 }}>
                          <NotificationsIcon />
                      </Avatar>
                  </Card>
                  
                    ))}
                </Box>
                
                )
            )}
        </Layout>
    );
};

export default Notifications;
