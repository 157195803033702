import React from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ChartDataLabels);

const BarChart = ({ labels, datasets, showValues }) => {
    const options = {
        plugins: {
            datalabels: {
                display: showValues, // Controllo display in base a showValues
                color: 'black',
                anchor: 'end',
                align: 'top',
                formatter: Math.round, // Mostra i valori arrotondati
            },
            legend: {
                display: true,
                position: 'top',
            },
        },
        responsive: true,
        maintainAspectRatio: false,
    };

    const data = {
        labels: labels,
        datasets: datasets,
    };

    return <Bar data={data} options={options} />;
};

export default BarChart;
