// src/components/CorsiCaricati.js
import React, { useState } from 'react';
import { Box, Typography, Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import Layout from './Layout';
import CorsiCaricatiTable from './CorsiCaricatiTable';

const CorsiCaricati = () => {
    const [subtype, setSubtype] = useState('tutte'); // Set default to 'tutte'

    const handleSubtypeChange = (event) => {
        setSubtype(event.target.value); // Update the selected subtype
    };

    return (
        <Layout>
            <FormControl component="fieldset">
                <RadioGroup
                    row
                    aria-label="corsi"
                    name="corsi"
                    value={subtype}
                    onChange={handleSubtypeChange}
                >
                    <FormControlLabel value="attuali" control={<Radio color="primary" />} label="MT con Corsi Attuali" />
                    <FormControlLabel value="futuri" control={<Radio color="primary" />} label="MT con Corsi Futuri" />
                    <FormControlLabel value="tutte" control={<Radio color="primary" />} label="Tutte le MT" />
                </RadioGroup>
            </FormControl>
            <Box sx={{ maxWidth: '100%' }}>
                <CorsiCaricatiTable subtype={subtype} />
            </Box>
        </Layout>
    );
};

export default CorsiCaricati;
