import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box, Grid, Tooltip, Chip } from '@mui/material';
import axios from '../axios';

const Presenze = ({ idCorso, idAlunno }) => {
    const [presenze, setPresenze] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');

        const fetchPresenze = async () => {
            try {
                const response = await axios.post('datahandler/corso-alunno-presenze', {
                    idCorso,
                    idAlunno,
                    token: token
                });
                const presenzeFiltrate = response.data.filter(item => item.mostralezione == "1");

                setPresenze(presenzeFiltrate);
                
            } catch (error) {
                console.error('Error fetching presenze:', error);
            }
        };

        fetchPresenze();
    }, [idCorso, idAlunno]);

    return (
        <Box mt={1}>
            <Typography variant="body2" color="textSecondary">Presenze:</Typography>
            <Grid container spacing={2} mt={1}>
                {presenze.map((presenza, index) => {
                    let backgroundColor = '#FF0000'; // Rosso di default
                    let tooltip = 'PRESENZA NON SALVATA';

                    if (presenza.presente === '1') {
                        backgroundColor = '#90ee90'; // Verde
                        tooltip = 'DinoAlunno presente';
                    } else if (presenza.presente ==='0') {
                        backgroundColor = '#ff6961'; // Rosso
                        tooltip = 'DinoAlunno assente';
                    }


                    return (
                        <Grid item key={index}>
                            <Tooltip title={tooltip} arrow>
                            <Chip
                                label={presenza.datalezione!=null?presenza.datalezione:"Lezione "+presenza.progr_lezione}
                                sx={{
                                    color: 'black',
                                    backgroundColor,
                                    boxShadow: 'inset 1px 1px 2px rgba(0, 0, 0, 0.6), inset -1px -1px 2px rgba(255, 255, 255, 0.6)',
                                    '&:hover': {
                                    backgroundColor: backgroundColor,
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    transform: 'scale(1.05)',
                                    },
                                    transition: 'all 0.2s ease-in-out',
                                }}
                                />
                                    
                               
                            </Tooltip>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

export default Presenze;
  